var s_account = "cdiscountcomprod";

function switchSearchForm(type) {
    var nav = document.getElementById("i-nav-search");
    if (nav != null) {
        var volTab = document.getElementById("i-nav-vols");
        var hotelTab = document.getElementById("i-nav-hotels");
        var carTab = document.getElementById("i-nav-cars");
        var searchVols = document.getElementById("i-search-vols");
        var searchHotels = document.getElementById("i-search-hotels");
        var searchCars = document.getElementById("i-search-cars");
        var currentActive = nav.getElementsByClassName("active").length > 0 ? nav.getElementsByClassName("active")[0] : null;
        var currentUnactiveDark = nav.getElementsByClassName("unactive-dark").length > 0 ? nav.getElementsByClassName("unactive-dark")[0] : null;
        
        $("#push-mobile-img-flight").hide();
        $("#push-mobile-img-hotel").hide();

        if (type == "Flight") {

            $("#push-mobile-img-flight").show();

            $("#hotel.op-compagnie").hide();
            $("#flight.op-compagnie").show();
            if (currentActive != null) currentActive.classList.remove("active");
            if (currentUnactiveDark != null) currentUnactiveDark.classList.remove("unactive-dark");
            volTab.classList.add("active");
            hotelTab.classList.add("unactive-dark");
            searchHotels.style.display = "none";
            searchVols.style.display = "block";
            $("#i-catch-phrase").empty();
            if (flightCatch1.length != 0) {
                if (flightCatchLineUrl != '') {
                    $('<a href="' + flightCatchLineUrl + '" style="text-decoration: none;" target="_blank"><span id="first-catch-phrase" class="tab-flight first">' + flightCatch1 + '</span></a>').appendTo("#i-catch-phrase");
                }
                else {
                    $('<span id="first-catch-phrase" class="tab-flight first">' + flightCatch1 + '</span>').appendTo("#i-catch-phrase");
                }
            }
            if (flightCatch2.length != 0) {
                if (flightCatchLineUrl != '') {
                    $('<br><a href="' + flightCatchLineUrl + '" style="text-decoration: none;" target="_blank"><span id="second-catch-phrase" class="tab-flight second">' + flightCatch2 + '</span></a>').appendTo("#i-catch-phrase");
                }
                else {
                    $('<br><span id="second-catch-phrase" class="tab-flight second">' + flightCatch2 + '</span>').appendTo("#i-catch-phrase");
                }
            }
            $(".c-hp-search").removeClass("c-hp-search-hotel");
        }
        else if (type == "Hotel") {
           $("#push-mobile-img-hotel").show();
            $("#flight.op-compagnie").hide();
            $("#hotel.op-compagnie").show();
            if (currentActive != null) currentActive.classList.remove("active");
            if (currentUnactiveDark != null) currentUnactiveDark.classList.remove("unactive-dark");
            hotelTab.classList.add("active");
            volTab.classList.add("unactive-dark");
            searchVols.style.display = "none";
            searchHotels.style.display = "block";
            $("#i-catch-phrase").empty();
            if (hotelCatchLine1.length != 0) {
                if (hotelCatchLineUrl != '') {
                    $('<a href="' + hotelCatchLineUrl + '" style="text-decoration: none;" target="_blank"><span id="first-catch-phrase" class="tab-hotel first">' + hotelCatchLine1 + '</span></a>').appendTo("#i-catch-phrase");
                }
                else {
                    $('<span id="first-catch-phrase" class="tab-hotel first">' + hotelCatchLine1 + '</span>').appendTo("#i-catch-phrase");
                }
            }
            if (hotelCatchLine2.length != 0) {
                if (hotelCatchLineUrl != '') {
                    $('<br><a href="' + hotelCatchLineUrl + '" style="text-decoration: none;" target="_blank"><span id="second-catch-phrase" class="tab-hotel second">' + hotelCatchLine2 + '</span></a>').appendTo("#i-catch-phrase");
                }
                else {
                    $('<br><span id="second-catch-phrase" class="tab-hotel second">' + hotelCatchLine2 + '</span>').appendTo("#i-catch-phrase");
                }
            }
            $(".c-hp-search").addClass("c-hp-search-hotel");
        }
        else if (type == "Car") {
            if (currentActive != null) currentActive.classList.remove("active");
            if (currentUnactiveDark != null) currentUnactiveDark.classList.remove("unactive-dark");
            carTab.classList.add("active");
            hotelTab.classList.add("unactive-dark");
            searchVols.style.display = "none";
            searchHotels.style.display = "block";
            // todo : config the tab's display
            $("#i-catch-phrase").empty();
            if (carCatchLine1.length != 0) {
                $('<a href="' + carCatchLineUrl + '" style="text-decoration: none;" target="_blank"><span id="first-catch-phrase" class="tab-car first">' + carCatchLine1 + '</span></a>').appendTo("#i-catch-phrase")
            }
            if (carCatchLine2.length != 0) {
                $('<br><a href="' + carCatchLineUrl + '" style="text-decoration: none;" target="_blank"><span id="second-catch-phrase" class="tab-car second">' + carCatchLine2 + '</span></a>').appendTo("#i-catch-phrase")
            }
        }

        $(document).trigger("tab-switched");

        return true;
    }
}

function getAllUrlParams(url) {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        var arr = queryString.split('&');

        for (var i = 0; i < arr.length; i++) {
            // separate the keys and the values
            var a = arr[i].split('=');

            // set parameter name and value (use 'true' if empty)
            var paramName = a[0];
            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {

                // create key if it doesn't exist
                var key = paramName.replace(/\[(\d+)?\]/, '');
                if (!obj[key]) obj[key] = [];

                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    var index = /\[(\d+)\]/.exec(paramName)[1];
                    obj[key][index] = paramValue;
                } else {
                    // otherwise add the value to the end of the array
                    obj[key].push(paramValue);
                }
            } else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    // if property does exist and it's a string, convert it to an array
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                } else {
                    // otherwise add the property
                    obj[paramName].push(paramValue);
                }
            }
        }
    }

    return obj;
}

function configureCdtm() {
    cdtm.configure({
        partnerId: "Y3uRk9qWUUzrLTh5",
        includeScopes: true,
        useLocalStorage: false,
        noConsentManagement: false,
        serverSideTcId: false,
        registerBrowserLifecycleHooks: true,
        pageChangeTimeout: 300
    });

    cdtm.setCommonVariables({
        ref: !0,
        url: !0,
        pageId: !0,
        lastPageId: !0,
        clickName: !0,
        clickDelay: !0,
        clickId: !0,
        originType: !0,
        locale: !0,
        refPagePixels: !0,
        refScrollPixels: !0
    });

    cdtm.collectExperimentalVariables({
        collectNetworkApiInfos: !0
    });

    console.info("****** CDTM configuration ******");
}

onReady(function () {

    document.addEventListener("click", function (event) {
        var headerDrop = document.getElementsByTagName("header")[0].getElementsByClassName("flight")[0];
        if (headerDrop) {
            var isClickInside = headerDrop.contains(event.target);
            if (isClickInside) {
                $('.dropdown-nav').toggleClass('show');
                $('.flight i').toggleClass('rotate');
            }
            else {
                $('.dropdown-nav').removeClass('show');
                $('.flight i').removeClass('rotate');
            }
        }
    });

    // OPEN CLOSE BURGER MENU
    $(document).on('click', '.header__burgerMenu__button, .header__burgerMenu__layer', function () {
        $('body').toggleClass('burgerMenu--open');
    });
    // OPEN CLOSE TEL
    $(document).on('click', '.header__tel__content--mobile__button--open, .header__tel__content--mobile__button--close', function () {
        $('body').toggleClass('tel--open');
    });
    //
    var urlParams = new URLSearchParams(window.location.search);
    var kayakclickid = urlParams.get('kayakclickid')
    if (kayakclickid) {
        window.sessionStorage.setItem('kayakclickid', kayakclickid);
    }
})


